@import './src/scss/variables';

.landing-page {
  display: flex;
  flex-direction: column;
  justify-content: left;
  padding-right: 135px;
  flex-basis: 50%;
  text-align: left;
  padding-left: 50px;
  padding-top: 100px;


  .landing-page-text-title {
    font-family: OpenSans-Bold;
    font-size: 28px;
    line-height: 1.25;
    letter-spacing: 0.53px;
    color: #333333;
  }

  .landing-page-text {
    padding-top: 23px;
    font-family: OpenSans-Regular;
    font-size: 14px;
    line-height: 1.5;
    letter-spacing: 0.53px;
    color: #3a3a3a;

  }

  .landing-button {
    padding-top: 35px;

    button {
      width: 206px;
      height: 45px;
      font-family: OpenSans-Bold;
      font-size: 14px;
      background-color: #a0c609;
      border-radius: 8px;
      letter-spacing: 0.1px;
      text-align: center;
      color: #ffffff;
      margin: auto;
      border: none;

      &:hover {
        background-color: #89A90B;
      }
    }
  }
}

.landing-page-img {
  flex: 0 0 50%;
  padding-right: 50px;
  padding-top: 100px;

  img {
    width: 100%;
  }
}

.landing-page-text-title-mob {
  font-family: OpenSans-Bold;
  font-size: 24px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: 0.45px;
  text-align: center;
  color: #333333;
  margin-bottom: 27px;
  margin-top: 23px;
}

.landing-page-img-mob {
  margin-right: 16px;
  margin-left: 16px;

  img {
    width: 100%;
  }
}

.landing-page-text-mob {
  margin-top: 23px;
  font-family: OpenSans-Regular;
  font-size: 14px;
  line-height: 1.58;
  letter-spacing: 0.31px;
  text-align: center;
  color: #333333;
}

.landing-button-mob {
  button {
    width: 206px;
    height: 45px;
    font-family: OpenSans-Bold;
    font-size: 14px;
    background-color: #a0c609;
    border-radius: 8px;
    letter-spacing: 0.1px;
    text-align: center;
    color: #ffffff;
    margin: auto;
    border: none;
  }
}

