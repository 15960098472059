.shop-card {
    @media screen and (max-width: "768px") {
        margin-bottom: 30px;
    }
    height: auto;
    margin-bottom: 30px;

    &-container {
        position:  absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }
    position: relative;
    width: 100%;
    padding-top: 85%; /* 4:3 Aspect Ratio */

    .image {
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        height: 65%;
    }
}