.landing-banner-text-overlay {
  width: 100%;
  height: 364px;
  //opacity: 0.38;
  mix-blend-mode: darken;
  background-image: url('../../images/shady-banner-home.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  align-items: center;
  .shady-banner-title {
    font-family: OpenSans-Bold;
    font-size: 30px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.23;
    letter-spacing: 0.56px;
    text-align: center;
    color: #ffffff;
  }
  .shady-banner-text {
    font-family: OpenSans-Regular;
    font-size: 20px;
    line-height: 1.35;
    letter-spacing: 0.52px;
    text-align: center;
    color: #ffffff;
  }
}

