@import "../../scss/variables";

.find-us-container {
  padding-left: 50px;
  padding-right: 45px;
  padding-top: 48px;
  padding-bottom: 40px;
  @media screen and (max-width: "1024px") {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 30px;
  }
  @media screen and (max-width: "576px") {
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 20px;
    padding-bottom: unset;
  }
}

.how-to-reach-us {
  font-family: OpenSans-Bold;
  font-size: 28px;
  line-height: 1.11;
  letter-spacing: 0.53px;
  color: #333333;
  text-align: center;

  &-title {
    margin-bottom: 26px;
  }

  .options {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 260px;
    margin: auto;
    margin-bottom: 30px;

    span {
      font-family: OpenSans-Bold;
      font-size: 20px;
      line-height: 1.55;
      letter-spacing: 0.38px;
      color: #333333;
      cursor: pointer;
    }

    .selected {
      color: $primary-color;
      text-transform: uppercase;
    }
  }
}

.reach-us-container {
  width: calc(100% + 30px); //we need more width for the shadows on the edges
  padding: 20px 20px; //space for the shadows
  margin-left: -20px; //put the div in its original position
  overflow: hidden; //hide the overflow
  .slick-list {
    overflow: visible;

  }

  .slick-slider {
    padding-bottom: 20px;
  }
}

.reach-us {
  height: 40vh;
  box-shadow: 0 0 27px 0 rgba(198, 198, 198, 0.36);
  background-color: #ffffff;
  padding-left: 18px;
  padding-right: 18px;
  padding-bottom: 24px;
  padding-top: 39px;
  margin-left: 10px;
  margin-right: 10px;
  @media screen and (max-width: "576px") {
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 20px;
    padding-bottom: unset;
    margin-left: 10px;
    margin-right: 10px;
  }

  &-img {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    width: 52px;
    height: 48px;
    margin-bottom: 21px;
  }

  &-title {
    font-family: OpenSans-Bold;
    font-size: 18px;
    line-height: 1.17;
    letter-spacing: 0.67px;
    text-align: center;
    color: #333333;
  }

  &-text {
    font-family: OpenSans-Regular;
    font-size: 14px;
    line-height: 1.5;
    letter-spacing: 0.36px;
    color: #333333;
    text-align: center;
    margin-top: 23px;
  }
}

.container-map-find-us {
  height: 413px;
  mix-blend-mode: color;
  @media screen and (max-width: "576px") {
    height: 294px;
  }
}

//.banner-img-find-us {
//  height: 100vh;
//  @media screen and (max-width: "576px") {
//    height: 100%;
//    width: 100vw;
//  }
//}
.leaflet-control-container {
  position: absolute;
  right: 56px;
}

.find-us-btn-div {
  margin-top: 32px;
  margin-bottom: 63px;
}

.find-us-btn {
  width: 270px;
  height: 45px;
  border-radius: 8px;
  background-color: $primary-color;
  text-transform: uppercase;
  font-family: OpenSans-Bold;
  font-size: 14px;
  letter-spacing: 0.1px;
  text-align: center;
  color: #ffffff;
  border: none;
  cursor: pointer;
  &:hover {
    background-color: #89A90B;
  }
}
