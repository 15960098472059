.storia {
  .storia-title {
    font-family: OpenSans-Bold;
    font-size: 28px;
    line-height: 1.25;
    letter-spacing: 0.53px;
    text-align: center;
    color: #333333;
    margin-top: 59px;
  }

  .storia-subtitle {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    font-family: OpenSans-Regular;
    font-size: 18px;
    line-height: 1.39;
    letter-spacing: 0.47px;
    text-align: center;
    color: #333333;
    margin-bottom: 62px;

  }

  .storia-landing-img {
    width: 100%;
    height: 626px;
    object-fit: cover;
  }

  .storia-text {
    margin-bottom: 48px;
    margin-top: 48px;
    width: 100%;
    font-family: OpenSans-Regular;
    font-size: 14px;
    line-height: 1.5;
    letter-spacing: 0.36px;
    color: #4c4c4c;
  }
}

.storia-container {
  //margin-bottom: 59px;
}

.storia-carousel-img {
  width: 547px;
  height: 399px;
  object-fit: contain;
}

.storia-grid-img {
  //margin-top: 50px;
  width: 100%;
  flex: 1 0;
}

.storia-grid-image {
  width: 100%;
}

.storia-grid-img-slider {
  width: 100%;
  object-fit: contain;
  margin-left: 20px;
  @media screen and (max-width: "576px") {
    margin-left: unset;
  }
}

.storia-grid-text {
  display: flex;
  align-items: center;
  font-family: OpenSans-Regular;
  font-size: 14px;
  line-height: 1.5;
  letter-spacing: 0.36px;
  color: #333333;
  flex: 1;

  &:first-child {
    // margin-right: 20px;
  }
}

.storia-banner {
  width: 100%;
  height: 400px;
  object-fit: cover;
  margin-top: 50px;
}

.storia-text-bottom-banner {
  width: 887px;
  height: 336px;
  font-family: OpenSans-Regular;
  font-size: 14px;
  line-height: 1.5;
  letter-spacing: 0.36px;
  color: #333333;
  margin-top: 64px;
}

.storia-slider {
  padding-left: 50px;
  padding-right: 50px;
  @media screen and (max-width: "576px") {
    padding-right: unset;
    padding-left: unset;
  }

  .slick-slider {
    padding-bottom: unset;
  }

  .slick-next {
    right: -54px;
    @media screen and (max-width: "1024px") {
      right: -40px;
    }
  }

  .slick-prev {
    left: -54px;
    @media screen and (max-width: "1024px") {
      left: -40px;
    }
  }
}

.storia-landing-img-mob {
  width: 375px;
  height: 183px;
  opacity: 0.38;
  mix-blend-mode: darken;
  background-color: #000000;
}

.banner-block-mob {
  // height: 10em;
  position: relative
}

.storia-mob-txt {
  font-family: OpenSans-Bold;
  font-size: 26px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.42;
  letter-spacing: 0.85px;
  color: #ffffff;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%)
}

.storia-title-mob {
  font-family: OpenSans-Bold;
  font-size: 24px;
  line-height: 1.29;
  letter-spacing: 0.45px;
  text-align: center;
  color: #333333;
  margin-top: 30px;
  margin-bottom: 20px;
}

.storia-subtitle-mob {
  font-family: OpenSans-Regular;
  font-style: italic;
  font-size: 16px;
  line-height: 1.44;
  letter-spacing: 0.41px;
  text-align: center;
  color: #333333;
  margin-bottom: 30px;
}

.storia-block-img1 {
  object-fit: contain;
  width: 100%;
}

.storia-block-img2 {
  object-fit: contain;
  width: 100%;
  margin-bottom: 30px;
}

.storia-txt-block-mob {
  font-family: OpenSans-Regular;
  font-size: 14px;
  line-height: 1.59;
  letter-spacing: 0.31px;
  color: #333333;
  align-self: center;
}

.storia-txt-block1-mob {
  font-family: OpenSans-Regular;
  font-size: 14px;
  line-height: 1.58;
  letter-spacing: 0.31px;
  color: #333333;
}