@import "../../../scss/variables";

.navbar {
    padding-left: 50px!important;
    padding-right: 40px!important;
    @media screen and (max-width: "768px") {
        padding-left: 20px!important;
        padding-right: 30px!important;
    }
    @media screen and (max-width: "576px") {
        padding-left: 15px!important;
        padding-right: 10px!important;
    }
    box-shadow: 0 2px 0 $primary-color;
    border-bottom: 2px solid $secondary-color;
    background-color: #ffffff;
    img {
        width: 189px;
        @media screen and (max-width: "576px") {
            width: 130px;
        }
    }
}

.navbar-nav .nav-link {
    padding-right: 0!important;
    padding-left: 0!important;
}

.navbar-light .navbar-toggler {
    color: #333333!important;
    border-color: transparent!important;
}

.header-top {
    padding-left: 50px;
    padding-right: 50px;
    @media screen and (max-width: "768px") {
        padding-left: 30px;
        padding-right: 35px;
    }
    @media screen and (max-width: "576px") {
        padding-left: 18px;
        padding-right: 15px;
    }
    height: 57px;
    background-image: linear-gradient(88deg, #25378d 0%, #1a71a5 100%);
    color: #ffffff;
    align-items: center;
    .pin {
        svg {
            width: 153px;
        }
        img {
            width: 153px;
        }
        &-link {
            width: 20px;
            height: 21px;
            object-fit: contain;
            background-color: #000000;
            color: white;
        }
    }
    .shop {
        svg {
            margin-right: 7px;
            width: 25px;
            height: 25px;
            object-fit: contain;
            background-color: white;
            border-radius: 50%;
            padding: 2px;
        }
        &-link {
            color: #ffffff;
            text-decoration: none;
        }
    }
}

.actions {
    align-items: center;
    a:hover {
        text-decoration: none;
    }
    span {
        cursor: pointer;
        width: 60px;
        height: 22px;
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.3px;
        color: #333333;
        margin-right: 30px;
        align-items: center;
        font-family: OpenSans-Bold;
        &:hover {
            border-bottom: 3px solid #e6001c;
        }
        &:active {
            border-bottom: 3px solid #e6001c;
        }
    }
    .nav-menu {
        text-transform: uppercase;
    }
    .actived {
        border-bottom: 3px solid #e6001c;    
    }
}
