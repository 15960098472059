$primary-color: #a0c609;
$secondary-color: #e6001c;
$top-header: #31668b;
$top-header-search:#d4d4d4;
$footer-bottom: #52670a;
$card-hover: rgba(0, 0, 0, 0.14);
$address-button: #f4f4f4;

$bp-mobile: 351px;
$bp-tablet: 576px;
$bp-desktop: 768px;
$bp-desktop-hd: 991px;

