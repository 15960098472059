.chi-siamo-title {
  font-family: OpenSans-Bold;
  font-size: 28px;
  line-height: 1.25;
  letter-spacing: 0.53px;
  text-align: center;
  color: #333333;
  margin-top: 59px;
}

.who-we-are-img {
  margin-top: 59px;
}

.chieri-image-title {
  font-family: OpenSans-Regular;
  font-size: 12px;
  font-style: italic;
  letter-spacing: 0.26px;
  text-align: left;
  color: #a2a2a2;
  margin-bottom: 50px;
  margin-top: 5px;

}

.bread-div {
  margin-top: 50px;
  margin-bottom: 40px;

  .bread-img {
    width: 557px;
    height: 349px;
    object-fit: contain;

  }

  .bread-img-text {
    font-family: OpenSans-Regular;
    margin-left: 40px;
    justify-content: center;
    margin-top: 87px;
    font-size: 14px;
    line-height: 1.5;
    letter-spacing: 0.36px;
    color: #3a3a3a;
    b {
      font-family: OpenSans-Bold;
    }

  }
}

b {
  font-family: OpenSans-Bold;
}

.bread-img-text-mob {
  font-family: OpenSans-Regular;
  font-size: 14px;
  line-height: 1.5;
  letter-spacing: 0.36px;
  color: #3a3a3a;
}

.chi-siamo-text {
  margin-bottom: 48px;
  margin-top: 48px;
  width: 100%;
  font-family: OpenSans-Regular;
  font-size: 14px;
  line-height: 1.5;
  letter-spacing: 0.36px;
  color: #424242;
  max-width: 880px;
  margin: auto;
}