.shops-container {
  padding-left: 50px;
  padding-right: 45px;
  //padding-top: 48px;
  padding-bottom: 40px;
  @media screen and (max-width: "768px") {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 30px;
  }
  @media screen and (max-width: "576px") {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 20px;
    padding-bottom: 26px;
  }

  &-title {
    font-family: OpenSans-Bold;
    font-size: 24px;
    line-height: 1.29;
    letter-spacing: 0.45px;
    color: #333333;
    margin-bottom: 13px;
    margin-top: 13px;
  }
  &-subtitle {
    font-family: OpenSans-Regular;
    font-size: 14px;
    @media screen and (max-width: "576px") {
      font-size: 14px;
    }
    line-height: 1.5;
    letter-spacing: 0.36px;
    color: #333333;
    margin-top: 32px;
    text-align: left;
    margin-bottom: 50px;
  }
}









