.news-card {
  @media screen and (max-width: "768px") {
    margin-bottom: 30px;
  }
}

.news-date {
  font-family: OpenSans-Bold;
  font-size: 12px;
  font-stretch: normal;
  font-style: italic;
  line-height: 1.75;
  letter-spacing: 0.31px;
  color: #6e6e6e;
  margin-top: 22px;
  margin-bottom: 9px;
}

.news-date-mob {
  margin-bottom: 7px;
  margin-top: 10px;
}

.card-container-news {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
  margin-right: 0px !important;
  margin-left: 0px !important;
}

.news-details-title {
  font-family: OpenSans-Bold;
  font-size: 28px;
  line-height: 1.25;
  letter-spacing: 0.53px;
  color: #333333;
  margin-top: 10px;
}

.news-details-subtitle {
  font-family: OpenSans-Regular;
  font-size: 18px;
  line-height: 1.39;
  letter-spacing: 0.47px;
  color: #333333;
  margin-top: 3px;
  margin-bottom: 31px;
}

.news-details-text {
  margin-top: 38px;
  font-family: OpenSans-Regular;
  font-size: 14px;
  line-height: 1.5;
  letter-spacing: 0.36px;
  color: #333333;
  margin-bottom: 33px;
  padding: 10px;
}

.news-detail-image {
  .news-details-side-text {
    border-bottom: 1px solid #d8d8d8;
    padding-bottom: 10px;
  }
  &:last-child {
    .news-details-side-text {
      border-bottom: none;
    }
  }
}


.news-details {
  padding-top: 33px;
  padding-bottom: 70px;
}

.news-card-date {
  font-family: OpenSans-Bold;
  font-size: 14px;
  font-style: italic;
  line-height: 1.75;
  letter-spacing: 0.31px;
  color: #6e6e6e;
}

.news-details-container {
  border-right: 1px solid #d8d8d8;
  padding-top: 30px;
}

.news-detail-image-card {
  object-fit: contain;
  width: 100%;
}

.news-details-side-heading {
  font-family: OpenSans-Bold;
  font-size: 20px;
  line-height: 1.75;
  letter-spacing: 0.38px;
  color: #333333;
  margin-top: 42px;

}

.news-details-side-image {
  width: 100%;
  font-family: OpenSans-Bold;
  font-size: 20px;
  line-height: 1.75;
  letter-spacing: 0.38px;
  color: #333333;
}

.news-details-side-text {
  font-family: OpenSans-Bold;
  font-size: 18px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.39;
  letter-spacing: 0.34px;
  color: #333333;
  margin-top: 9px;
  margin-bottom: 39px;

  p {
    display: block;
    display: -webkit-box;
    text-overflow: ellipsis;
    overflow: hidden;
    word-wrap: break-word;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

.news-details-mob-title {
  font-family: OpenSans-Bold;
  font-size: 24px;
  line-height: 1.46;
  letter-spacing: 0.45px;
  color: #333333;
  margin-top: 13px;
  margin-bottom: 11px;
}

.news-details-mob-text {
  font-family: OpenSans-Regular;
  font-size: 16px;
  line-height: 1.56;
  letter-spacing: 0.41px;
  color: #333333;
  text-align: justify;
  margin-bottom: 22px;
}

.news-detail-images {
  width: 100%;
}

.news-details-mob-image {
  width: 100%;
  margin-bottom: 35px;
}

.news-details-mob-text-block {
  font-family: OpenSans-Regular;
  font-size: 12px;
  line-height: 1.75;
  letter-spacing: 0.31px;
  color: #333333;
  margin-bottom: 31px;
}

.news-detail-bottom-block {
  font-family: OpenSans-Bold;
  font-size: 18px;
  line-height: 1.72;
  letter-spacing: 0.34px;
  color: #333333;
  margin-top: 41px;
  margin-bottom: 16px;
}

.news-details-mob-sm-image {
  width: 100%;
  height: 94px;
  object-fit: contain;
}


.news-detail-bottom-block-text {
  font-family: OpenSans-Bold;
  font-size: 14px;
  line-height: 1.5;
  letter-spacing: 0.26px;
  color: #333333;
  margin-bottom: 20px;
}

.news-details-link-img {
  margin: 15px;
  padding: 10px;
}

.news-card-text {
  margin-bottom: 30px;
  font-family: OpenSans-Bold;
  font-size: 18px;
  line-height: 1.39;
  letter-spacing: 0.34px;
  color: #333333;
}

.news-container {
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 48px;
  padding-bottom: 40px;
  @media screen and (max-width: "768px") {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 30px;
  }
  @media screen and (max-width: "576px") {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 20px;
    padding-bottom: 26px;
  }

}