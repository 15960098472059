@import "../../../scss/variables";

.showcase-news {
  height: 31px;
  font-family: OpenSans-Bold;
  font-size: 28px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.11;
  letter-spacing: 0.53px;
  text-align: center;
  color: #333333;
  align-content: center;
}
.news-title {
  max-width: 546px;
  font-family: OpenSans-Bold;
  font-size: 18px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.39;
  letter-spacing: 0.34px;
  color: #333333;
  text-align: left;
  margin-bottom: 20px;
}
.news-text {
  max-width: 546px;
  //height: 63px;
  font-family: OpenSans-Regular;
  font-size: 14px;
  line-height: 1.5;
  letter-spacing: 0.36px;
  color: #333333;
  text-align: left;
 }

.showcase-button {
  margin-top: 50px;
  margin-bottom: 87px;
  @media screen and (max-width: "768px") {
    margin-bottom: 40px;
  }
}

.showcase-bottom-button {
  display: flex;
  
  button {
    width: 206px;
    height: 45px;
    border-radius: 8px;
    background-color: #a0c609;
    border: none;
    font-family: OpenSans-Bold;
    font-size: 14px;
    letter-spacing: 0.1px;
    text-align: center;
    color: #ffffff;
    &:hover {
      background-color: #89A90B;
    }
  }
}

.shady-banner-title-mob {
  font-family: OpenSans-Bold;
  font-size: 24px;
  line-height: 1.29;
  text-align: center;
  color: #ffffff;
}

.shady-banner-text-mob {

font-family: OpenSans-Regular;
font-size: 14px;
line-height: 1.5;
letter-spacing: 0.36px;
text-align: center;
color: #ffffff;
}

.news-image-mob {
  img {
    @media screen and (max-width: "576px") {
      width: 100%;
    }
  }
}
.slider-image-tab {

}
.news-text-mob {
  font-family: OpenSans-Regular;
  font-size: 14px;
  line-height: 1.75;
  letter-spacing: 0.31px;
  text-align: center;
  color: #333333;
  max-width: 344px;
  margin: auto;
  margin-top: 6px;
}

.showcase-news-mob {
  font-family: OpenSans-Bold;
  font-size: 28px;
  line-height: 1.11;
  letter-spacing: 0.53px;
  text-align: center;
  color: #333333;
  margin-bottom: 15px;
}

.news-title-mob {
  width: 100%;
  max-width: 344px;
  margin: auto;
  font-family: OpenSans-Bold;
  font-size: 18px;
  line-height: 1.39;
  letter-spacing: 0.34px;
  text-align: center;
  color: #333333;
}

.showcase-slider {
  padding-left: 79px;
  padding-right: 80px;
  padding-top: 40px;
  background-color: #fafafa;
  @media screen and (max-width: "576px") {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 24px;
  }
  .slick-slide img {
    @media screen and (max-width: "1024px") {
      max-width: 400px;
      height: 100%;
    }
  }
}

.showcase-element { 
  outline: none;
}

.slick-dots {
  z-index: 9999;
}

.slick-slider {
  padding-bottom: 40px;
}

.slick-arrow {
  svg {
    width: 30px;
    height: 30px; 
    path {
      fill: $primary-color;
    }
  }
}
