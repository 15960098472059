.showcase-button {
  font-family: OpenSans-Bold;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.1px;
  text-align: center;
  margin-bottom: 227px;
  display: flex;
  justify-content: center;
  flex-direction: column;


  button {
    width: 247px;
    height: 45px;
    border-radius: 8px;
    background-color: #a0c609;
    align-items: center;
    border: none;
    color: #ffffff;
    &:hover {
      background-color: #89A90B;
    }

  }
}

.landing-stores-title {
  font-family: OpenSans-Bold;
  font-size: 28px;
  line-height: 1.11;
  letter-spacing: 0.53px;
  text-align: center;
  color: #333333;
  margin-top: 99px;
  margin-bottom: 31px;
}
.abbigliamento-mob {
  font-family: OpenSans-Regular;
  font-size: 14px;
  letter-spacing: 0.07px;
  color: #616161;
  text-align: left;
  margin-top: 10px;
  margin-bottom: 10px;
}
.abbigliamento-home-web {
  font-family: OpenSans-Regular;
  font-size: 12px;
  letter-spacing: 0.07px;
  color: #616161;
  text-align: left;
  margin-top: 10px;
  margin-bottom: 10px;

  display: block;
  display: -webkit-box;
  text-overflow: ellipsis;
  overflow: hidden;
  word-wrap: break-word;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: nowrap;
}


.shope-name-home-web, .shop-name-home-mob {
  font-family: OpenSans-Bold;
  font-size: 18px;
  line-height: 1.17;
  letter-spacing: 0.67px;
  color: #333333;
  text-align: left;
  display: block;
  display: -webkit-box;
  text-overflow: ellipsis;
  overflow: hidden;
  word-wrap: break-word;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-bottom: .5rem;
  height: 20px;
  white-space: nowrap;
}

.shop-address-web {
  font-family: OpenSans-Regular;
  font-size: 14px;
  letter-spacing: 0.09px;
  color: #616161;
  text-align: left;
  display: block;
  display: -webkit-box;
  text-overflow: ellipsis;
  overflow: hidden;
  word-wrap: break-word;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-bottom: .5rem;
  height: 20px;
  white-space: nowrap;
  //padding-top: 5px;

}

.shop-address-mob {
  margin-bottom: 25px;
  font-family: OpenSans-Regular;
  font-size: 14px;
  letter-spacing: 0.09px;
  color: #616161;
  text-align: left;
  display: block;
  display: -webkit-box;
  text-overflow: ellipsis;
  overflow: hidden;
  word-wrap: break-word;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-bottom: .5rem;
  height: 20px;
  white-space: nowrap;
}

.carousel-dots {
  display: flex;
  width: 100%;
  ul {
    margin: auto;
  }
}